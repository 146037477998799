import React from 'react'
import Button from '@material/react-button'
import { FormattedMessage } from 'react-intl'
import { navigate } from 'gatsby'

export default function ButtonMore({ url }) {
  return (
    <Button
      href={url}
      onClick={e => {
        e.preventDefault()
        navigate(url)
      }}
    >
      <FormattedMessage id="common.button_more" />
    </Button>
  )
}
