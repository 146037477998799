import React from 'react'

import Grid, { Cell } from '../Grid'

export default ({ children }) => (
  <Grid className="im-cards">
    {React.Children.map(children, (item, index) => (
      <Cell
        key={index}
        className="mdc-layout-grid__cell--span-4 mdc-layout-grid__cell--span-6-desktop"
      >
        {item}
      </Cell>
    ))}
  </Grid>
)
