import React from 'react'
import ButtonMore from './ButtonMore'

const Block = ({ title, children, link }) => (
  <div className="block">
    <h2 className="block__title">{title}</h2>
    <div className="block__content">{children}</div>
    {link && (
      <div className="block__more">
        <ButtonMore url={link}/>
      </div>
    )}
  </div>
)

export default Block
