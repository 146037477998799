import React from 'react'
import { Link, navigate } from 'gatsby'
import classnames from 'classnames'

import Card, {
  CardActionButtons,
  CardActionIcons,
  CardActions,
  CardPrimaryContent,
  CardMedia,
} from '@material/react-card'
import ALink from '../ALink'

export const BaseBox = ({
  handleClick,
  children,
  className,
  ...otherProps
}) => {
  const classes = classnames('im-card__content')
  return (
    <Card outlined className={`${className} im-card`}>
      <CardPrimaryContent
        className={classes}
        onClick={handleClick}
        {...otherProps}
      >
        {children}
      </CardPrimaryContent>
    </Card>
  )
}

const DefaultBox = ({ title, children, ...otherProps }) => (
  <BaseBox {...otherProps}>
    <h3 className="im-card__title mdc-typography--headline6">{title}</h3>
    {children}
  </BaseBox>
)

const withLink = WrappedComponent => ({ link, ...otherProps }) => {
  const handleClick = () => {
    navigate(link)
  }
  return <ALink to={link}><WrappedComponent {...otherProps} handleClick={handleClick} /></ALink>
}

export const BoxIcon = ({ imageUrl }) => (
  <div className="im-card__icon">
    <img src={imageUrl} />
  </div>
)

export const BoxWithLink = withLink(DefaultBox)
export const BaseBoxWithLink = withLink(BaseBox)

export default DefaultBox
