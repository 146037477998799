import React from 'react'
import { FormattedMessage } from 'react-intl'

import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { BaseBoxWithLink, BoxWithLink, BoxIcon } from './Box'
import { urlFor } from '../LinkI18n'
import { injectIntl } from 'react-intl'

import frontendUrl from '../../images/images/frontend.svg'
import backendUrl from '../../images/images/backend.svg'
import websitesUrl from '../../images/images/websites.svg'
import writtingUrl from '../../images/images/writting.svg'


export const ServicesFrontendBox = injectIntl(({ intl }) => (
  <BoxWithLink
    link={urlFor(intl.locale, 'services/frontend-web-development/')}
    className="im-card--services"
    title={<FormattedMessage id="boxes.services_frontend_box.title" />}
  >
    <BoxIcon imageUrl={frontendUrl} />
    <p className="im-card__description">
      <FormattedMessage id="boxes.services_frontend_box.description" />
    </p>
  </BoxWithLink>
))

export const ServicesBackednBox = injectIntl(({ intl }) => (
  <BoxWithLink
    link={urlFor(intl.locale, 'services/backend-web-development/')}
    title={<FormattedMessage id="boxes.services_backend_box.title" />}
  >
    <BoxIcon imageUrl={backendUrl} />
    <p className="im-card__description">
      <FormattedMessage id="boxes.services_backend_box.description" />
    </p>
  </BoxWithLink>
))

export const ServicesWritingBox = injectIntl(({ intl }) => (
  <BoxWithLink
    link={urlFor(intl.locale, 'services/writing/')}
    className="im-card--services"
    title={<FormattedMessage id="boxes.services_writing_box.title" />}
  >
    <BoxIcon imageUrl={writtingUrl} />
    <p className="im-card__description">
      <FormattedMessage id="boxes.services_writing_box.description" />
    </p>
  </BoxWithLink>
))

export const ServicesWebsitesBox = injectIntl(({ intl }) => (
  <BoxWithLink
    link={urlFor(intl.locale, 'services/websites/')}
    className="im-card--services"
    title={<FormattedMessage id="boxes.services_websites_box.title" />}
  >
    <BoxIcon imageUrl={websitesUrl} />
    <p className="im-card__description">
      <FormattedMessage id="boxes.services_websites_box.description" />
    </p>
  </BoxWithLink>
))

export const Get1BikeBox = injectIntl(({ intl }) => (
  <StaticQuery
    query={graphql`
      query {
        img: file(relativePath: { eq: "bck-get1bike.jpg" }) {
          ...Thumbnail
        }
      }
    `}
    render={data => (
      <BaseBoxWithLink
        link={urlFor(intl.locale, 'portfolio/get1bike/')}
        className="im-card--horizontal im-card--get1bike"
      >
        <div className="im-card__content-content">
          <h2 className="im-card__title mdc-typography--headline6">
            <FormattedMessage id="boxes.get1bike.title" />
          </h2>
          <p className="im-card__description">
            <FormattedMessage id="boxes.get1bike.description" />
          </p>
        </div>
        <div className="mdc-card__media im-card__content-media">
          <Img fluid={data.img.childImageSharp.fluid} />
        </div>
      </BaseBoxWithLink>
    )}
  />
))
